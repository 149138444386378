body{
    background-color: transparent !important
}

.custom-button{
    background-color: #00589E !important;
    color:#FFFFFF !important;
    font-family:Verdana, Geneva, Tahoma, sans-serif !important;

}

.custom-button:hover{
    background-color: #033966 !important;
    color:#FFFFFF;
}

.danger{
    color: red;
}
