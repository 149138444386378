.filter .col, .table-apprpaxedit .col {
    max-width: 1100px;
    border-radius: 6px;
}

.custom-color-head{
    background-color: #2A3F64;
    color:white;
}



.create-form-apprpaxedit{
    max-width: 1100px;
    color:#00476f;
    font-size: 13px;

}

