.content {
    font-size: 14px;
}

.content .col{
    max-width: 1100px;
}

.cont{
    max-width: 950px;
}

.ala{
    font-family: Courier ;

}

.content .custom-color-head{
    background-color: #aaddff;
    color:#00476f;
}

.content th,.content td {
    padding: 0;
    margin: 0;
  }

  .afdnform{
    border: 3px solid #afaeae;
    border-radius: 6px;


  }

  .content table{
    font-size: 12px;
  }
