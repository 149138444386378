/*Filter*/
a{
    color: #2A3F64 !important;
}

.boxdivider{
  background-color: #f3f5f7;
}

.filter .col, .table .col {
    max-width: 1100px;
    background-color: white;
    border-radius: 6px;
}


.custom-color-head{
    background-color: #2A3F64;
    color:white;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #EFF3FB; /* Choose your own color here */
}

.boldfont{
    font-weight: 500;
}
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }

 .floatRight {
    float: right;
 }
 .formerror {
    color: red;
    font-size: 14px;
    line-height: 26px;    
  }
  .formsucess {
    color: green;
    font-size: 14px;
    line-height: 26px;    
  }