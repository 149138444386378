p, span{
    font-size: 14px;  
}

.filter .col, .table .col {
    max-width: 1100px;
    background-color: white;
    border-radius: 6px;
}


.custom-color-head{
    background-color: #2A3F64;
    color:white;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #EFF3FB; /* Choose your own color here */
}

button{
    border: 1px solid grey !important;
    
}

.custom-button{
    background-color: #00589E;
    color:#FFFFFF;
    font-family:Verdana, Geneva, Tahoma, sans-serif;

}

.custom-button:hover{
    background-color: #033966;
    color:#FFFFFF;
}

a{
    color: #2A3F64;
}
