.filter .col, .appr-create-table .col {
    max-width: 1100px;
    border-radius: 6px;
}

.custom-color-head{
    background-color: #2A3F64;
    color:white;
}



.create-form{
    max-width: 1100px;
    color:#00476f;
    font-size: 13px;

}


.custom-button{
    background-color: #00589E;
    color:#FFFFFF;
    font-family:Verdana, Geneva, Tahoma, sans-serif;

}

.custom-button:hover{
    background-color: #033966;
    color:#FFFFFF;
}
